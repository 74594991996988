import React, { useEffect, useState } from "react";

import { useWindowScroll } from "react-use";
import { CourseEvent, CourseEventBookingUrl } from "../../models/CourseEvent";
import moment from "moment";
import {
    EventRegisterSection,
    MobileTimeContainer,
    RegisterButton,
    RegisterButtonText,
    RegisterContainer,
    RegisterTextContainer,
    TimeContainer,
} from "./styles/EventRegister";
import classNames from "classnames";
import Button from "components/shared/Button";

interface EventRegisterProps {
    courseEvent: CourseEvent;
}

const EventRegister = (props: EventRegisterProps) => {
    const { courseEvent } = props;
    const { y: pageYOffset } = useWindowScroll();
    const [scrolled, setScrolled] = useState<boolean>(false);

    useEffect(() => {
        if (pageYOffset > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    }, [pageYOffset]);

    const getDateAndTime = (shouldReturnTime: boolean) => {
        const startTime = moment(courseEvent.startDate).format("h:mm a");
        const endTime = moment(courseEvent.endDate).format("h:mm a");

        const day = moment(courseEvent.startDate).format("MMMM DD YYYY");

        return shouldReturnTime ? `${day}, ${startTime}-${endTime}` : day;
    };

    return (
        <div className={classNames("position-fixed bottom-0 w-full bg-primary transition-transform z-10", {
            "translate-y-full": !scrolled,
            "translate-y-0": scrolled
        })}>
            <div className="container">
                <EventRegisterSection>
                    <RegisterTextContainer>
                        <MobileTimeContainer>
                            {courseEvent.title}:<br /> {getDateAndTime(false)}
                        </MobileTimeContainer>
                        <TimeContainer>
                            {courseEvent.title}: {getDateAndTime(true)}
                        </TimeContainer>
                    </RegisterTextContainer>
                    <Button
                        variant="secondary"
                        {...(courseEvent.allowAnonymous ? { href: courseEvent.bookingUrl } : { to: CourseEventBookingUrl(courseEvent) })}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Register now
                    </Button>
                </EventRegisterSection>
            </div>
        </div>
    );
};

export default EventRegister;
