import React from 'react'
import {
  CpdHoursLabel,
  CpdInnerTable,
  CpdInnerTableContainer,
  CpdItem,
  CpdNoteText,
  CpdTable,
  CpdTitle,
  CpdValue,
  EventRacpgContentContainer,
  EventRacpgDescriptionContainer,
  EventRacpgImageContentContainer,
  EventRacpgSection,
  EventRacpgSectionContainer,
  EventRacpgTitleContainer,
  EventRacpgTitleOrangeContainer,
  RacgpCpdApprovedActivityLogo,
} from './styles/EventRacgp'
import { CourseEvent } from '../../models/CourseEvent'
import racgpCpdApprovedActivityLogo from '../../images/racgpImage.png'

interface EventRacgpProps {
  courseEvent: CourseEvent
}

function renderCgpItem(title: string, hours: number) {
  return <div className='flex-1 shrink-0 text-gray-900 p-2'>
    <div className='text-sm sm:text-base font-extrabold text-center'>
      {title}
    </div>
    <div className='font-black text-center text-3xl'>
      {hours}
    </div>
    <div className='text-sm text-center'>hours</div>
  </div>
}

const EventRacgp = (props: EventRacgpProps) => {
  const { courseEvent } = props

  return (
    <section className='py-12'>
      <div className="container">
        <div className='flex gap-6 flex-wrap md:flex-nowrap'>
          <div className='basis-0 max-w-full'>
            <div>
              {/* <CpdTable> */}
              <div className="p-1 bg-white border-4 border-racgp">
                <img
                  className='w-auto'
                  src={racgpCpdApprovedActivityLogo}
                  alt='RACGP CPD Approved Activity'
                />
                <div className="bg-racgp flex justify-around align-items-center gap-8">
                  {renderCgpItem("Educational Activities", courseEvent.course.educationalActivitiesCpd)}
                  {renderCgpItem("Measuring Outcomes", courseEvent.course.measuringOutcomesCpd)}
                  {renderCgpItem("Reviewing Performance", courseEvent.course.reviewingPerformanceCpd)}
                </div>
              </div>
              {/* </CpdTable> */}
              {courseEvent.cpdHoursFootnote &&
                <p className='text-xs my-2 text-gray-400'>
                  ** {courseEvent.cpdHoursFootnote}
                </p>
              }
            </div>
          </div>
          <div className='flex-1 min-w-72'>
            <div className='h-full flex flex-col justify-center'>
              <EventRacpgTitleContainer>
                <span>
                  This event is an{' '}
                  <span className="text-primary">
                    RACGP CPD accredited
                  </span>{' '}
                  hybrid activity
                </span>
              </EventRacpgTitleContainer>
              <p>
                {courseEvent.racpgDescription}
              </p>
            </div>
          </div>
        </div></div>
    </section>
  )
}

export default EventRacgp
