import './GuestLandingPageLayout.scss'
import * as React from 'react'
import { LandingPageHeader } from '../components/shared/LandingPageHeader'
import * as dashboard from '../store/dashboardStore'
import { connect } from 'react-redux'
import { IApplicationState } from '../store'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { CourseEvent } from '../models/CourseEvent'
import { PageContent } from './styles/GuestLayout'
import Footer from '../components/shared/Footer'

interface IProps {
  hideHeader?: boolean
  hideWrapper?: boolean
}

type Props = IProps &
  dashboard.DashboardState &
  React.PropsWithChildren<{}> &
  typeof dashboard.actionCreators
const GuestLandingPageLayout: React.FC<Props> = (props: Props) => {
  const { fetchAllUpcomingCourseEvents, allUpcomingCourseEvents } = props

  useEffect(() => {
    fetchAllUpcomingCourseEvents('dateAdded')
  }, [])

  const location = useLocation()

  const getTopUpcomingEvent = (upcomingEvents: CourseEvent[]) => {
    const topUpcomingEvent =
      upcomingEvents.length > 0 ? upcomingEvents[0] : null
    if (
      topUpcomingEvent != null &&
      location.pathname !== `/event/${topUpcomingEvent.id}`
    ) {
      return topUpcomingEvent
    }

    return null
  }

  const topUpcomingEvent = getTopUpcomingEvent(allUpcomingCourseEvents)

  return (
    <React.Fragment>
      <div className="guest-layout">
        {!props.hideHeader && (
          <LandingPageHeader
            upcomingEvents={allUpcomingCourseEvents}
            topUpcomingEvent={topUpcomingEvent}
          />
        )}
        <PageContent topBannerIsVisible={topUpcomingEvent != null}>
        <div className={props.hideWrapper ? '' : 'landing-page-main'}>
          {props.children}
        </div>
        </PageContent>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default connect(
  (state: IApplicationState) => ({
    ...state.dashboard,
  }),
  { ...dashboard.actionCreators },
)(GuestLandingPageLayout as any)
