import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { VariantArgs, variant } from "styled-system";
import { device } from "../../styles/sizes/device";

interface BaseProps extends VariantArgs {
    variant?: "primary" | "secondary";
    shouldNotExpand: boolean;
}

const Base = styled.button<BaseProps>(
    {
        display: "flex",
        padding: "10px 18px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        appearance: "none",
        borderRadius: "4px",

        fontStyle: "normal",
        lineHeight: "24px",
        fontWeight: 600,
        fontSize: "16px",
        transition: "all 0.3s ease",
        width: "fit-content",
    },
    (props) => ({
        [`@media ${device.xs}`]: {
            width: props.shouldNotExpand ? "fit-content" : "100%",
        },
    }),
    variant({
        variants: {
            primary: {
                border: "1px solid var(--primary-orange, #F47721)",
                background: "var(--primary-orange, #F47721)",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                color: "var(--white, #FFF)",
                "&:hover": {
                    border: "1px solid var(--primary-700, #F46E15)",
                    background: "var(--primary-700, #F46E15)",
                    color: "var(--white, #FFF)",
                },
            },
            secondary: {
                color: "var(--gray-700, #344054)",
                border: "1px solid var(--gray-300, #D0D5DD)",
                background: "var(--white, #FFF)",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                "&:hover": {
                    border: "1px solid var(--secondary-light-grey, #D1D3D4)",
                    background: "var(--secondary-light-grey-3, #F7F7F7)",
                    color: "var(--secondary-dark-grey, #666)",
                },
            },
        },
    })
);

export interface ButtonProps {
    variant?: "primary" | "secondary";
    href?: string;
    to?: string;
    target?: React.AnchorHTMLAttributes<HTMLAnchorElement>["target"];
    rel?: React.AnchorHTMLAttributes<HTMLAnchorElement>["rel"];
    children?: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    shouldNotExpand?: boolean;
    className?: string;
    disabled?: boolean;
    type?: "button" | "reset" | "submit";
}

const Button: React.FC<ButtonProps> = ({
    variant = "primary",
    href,
    to,
    target,
    rel,
    children,
    onClick,
    shouldNotExpand,
    className = "",
    disabled = false,
    type = "button"
}) => {
    if (href) {
        return (
            <Base
                as="a"
                href={href}
                target={target}
                rel={rel}
                variant={variant}
                shouldNotExpand={shouldNotExpand}
                className={className}
            >
                {children}
            </Base>
        );
    }

    if (to) {
        return (
            <Base
                as={Link}
                to={to}
                variant={variant}
                shouldNotExpand={shouldNotExpand}
                className={className}
            >
                {children}
            </Base>
        );
    }

    return (
        <Base
            onClick={onClick}
            variant={variant}
            shouldNotExpand={shouldNotExpand}
            className={className}
            disabled={disabled}
            type={type}
        >
            {children}
        </Base>
    );
};

export default Button;
